/*PRE EVENTS*/

.cardImage{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.rcard{
  /* width: clamp(180px, 20vw, 240px);  */
  /* Min 180px, max 240px, adjusts with viewport */
  /* height: clamp(280px, 30vw, 360px);  */
  width: 260px;
  height: 365px;
  perspective: 1000px;
}


.card-wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /*center*/
  width: 100%;
  height: 100%;
  /* animation: floating 3s infinite ease-in-out; */
}

.cards{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
}


@media(max-width: 768px){
  .card-wrapper{
    animation: none;
    position: static;
    transform: none;
  }

  .rcard{
    position: static;
    transform: none;
  }
}



#card-1 .card-wrapper{
  animation-delay: 0s;
}

#card-2 .card-wrapper{
  animation-delay: 0.2s;
}
#card-3 .card-wrapper{
  animation-delay: 0.4s;
}
#card-4 .card-wrapper{
  animation-delay: 0.6s;
}


.flip-card-inner{
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back{
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 0.4rem;
  overflow: hidden;
}

.flip-card-back{
  padding: 1rem;
  background-color: #E2DCD0;
  transform: rotateY(180deg);
}


@keyframes floating{
  0%{
    transform: translate(-50%, -50%);
  }

  50%{
    transform: translate(-50%, -60%);
  }

  100%{
    transform: translate(-50%, -50%);
  }
}






/* Timeline.css */


.timeline {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}


.timeline-line {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 50%;
    width: 2px;
    background-color: white;
}

.timeline-event {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 75px;
    justify-content: center;
    margin: 20px 0;
    background-color: white;
    /* border: 2px solid #D71015; */
}



.timeline-connector {
    position: absolute;
    top: 129%;
    left: 50.4%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    z-index: 1;
}
@font-face {
    font-family: 'Neptune';
    src: url('./assets/font/Neptune.woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ProductSans';
    src: url('./assets/font/ProductSans-Light.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'BrunoAce';
    src: url('./assets/font/BrunoAce-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Dirtyline';
    src: url('./assets/font/Dirtyline.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.app{
    overflow: hidden;
    overflow-y: hidden;
}


.mybtn {
    align-items: center;
    
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Neptune, sans-serif;
    font-size: 38px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 200px;
    min-height: 80px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
    margin-right: 20px;
    margin-left: 20px;

   }
   
   .mybtn:active,
   .mybtn:hover {
    outline: 0;
   }
   
   .mybtn span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
   }
   
   .mybtn:hover span {
    background: none;
   }
   
   .mybtn:active {
    transform: scale(0.9);
   }
